import React from "react";
import { Navigate } from "react-router-dom";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";
const ProtectedRoutes = ({ isAuth, redirectPath = "/sign-in", children }) => {
  if (!isAuth) {
    return <Navigate to={redirectPath} replace />;
  }
  return (
    <>
      <LiveChatWidget license="15633603" visibility="minimized" />
      {children}
    </>
  );
};
export default ProtectedRoutes;
