import { casinoTabs } from "./casinoTabs.data";

export const EzugiGames = {
  ...casinoTabs.roulettetab,
  ...casinoTabs.baccarattab,
  ...casinoTabs.AndarBahartab,
  ...casinoTabs.TeenPattitab,
  ...casinoTabs.Blackjacktab,
  ...casinoTabs.thirtytwoCardtab,
  ...casinoTabs.sevenLuckytab,
  ...casinoTabs.sicbotab,

  lucky7: {
    href: "/casino/ezugi/lucky7",
    code: "228001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/01.jpg", alt: "" },
  },
};
