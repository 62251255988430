import React, { PureComponent, useEffect } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

const AboutUs = () => {
  useEffect(() => {
    updateMeta();
  }, []);
  const updateMeta = () => {
    document.title = "About Us – Rocky11";
    document
      ?.querySelector("meta[name='description']")
      ?.setAttribute(
        "content",
        "Rocky11 is One of the best websites for Sports Betting, casinos, Esports and Poker. Rocky11 is a secure platform for all gambling enthusiasts to have fun in a safe and responsible gaming environment. Fastest Withdrawals & deposits, 24/7 customer service"
      );
    document
      ?.querySelector("meta[name='keywords']")
      ?.setAttribute(
        "content",
        "live casino, premium games, Andar bahar, betting exchange, card games, games cricket."
      );
  };
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main pagecontent">
          <div className="container">
            <h2 class="sectionTitle"> About Us</h2>
            <div className="terms-content">
              <p>
                {" "}
                Welcome to our betting website! We are dedicated to providing
                you with an exceptional online betting experience. Our platform
                offers a wide range of exciting betting options across various
                sports and events, ensuring there's something for every sports
                enthusiast. Whether you're a passionate fan or a casual bettor,
                our user-friendly interface and intuitive features make it easy
                to navigate and place your bets with confidence.
              </p>
              <p>
                {" "}
                At our betting website, we prioritize fairness, security, and
                transparency. We have implemented stringent measures to protect
                your personal information and ensure the integrity of our
                platform. Our commitment to responsible gambling means we
                provide tools and resources to help you maintain control and
                make informed decisions.{" "}
              </p>
              <p>
                {" "}
                With a team of experienced oddsmakers, we strive to provide
                competitive odds and a diverse selection of betting markets.
                From popular sports like football, basketball, and tennis to
                niche markets and special events, we aim to cater to all your
                betting preferences.{" "}
              </p>
              <p>
                In addition to our comprehensive sportsbook, we offer live
                betting options that allow you to immerse yourself in the action
                as it unfolds. You can enjoy real-time updates, dynamic odds,
                and the thrill of making in-play wagers.
              </p>
              <p>
                Customer satisfaction is our top priority, and we take pride in
                offering excellent customer support. Our knowledgeable support
                team is available to assist you with any queries or concerns you
                may have, ensuring a smooth and enjoyable betting experience.{" "}
              </p>
              <p>
                Join our betting website today and embark on an exciting journey
                filled with thrilling sports action, lucrative betting
                opportunities, and a community of fellow sports enthusiasts.
                Place your bets responsibly and let the excitement begin!
              </p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default AboutUs;
