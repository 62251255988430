import React from "react";
import { useSelector } from "react-redux";

import HeaderAfterLogin from "../../../containers/HeaderAfterLogin";
import Header from "../../../containers/Header";
import BeforeLoginFooter from "../../../containers/BeforeLoginFooter";
import Footer from "../../../containers/Footer";

const ComingSoon = () => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      {isAuth ? <HeaderAfterLogin /> : <Header />}
      <div className="mt-5 mb-2 legalPage">
        <h3>Krishna365, legal betting platform.</h3>
        <p>
          Starting in 2017, krishna365 has been one of the top and biggest
          businesses when It comes to legal online betting platform , which
          provides Indian gamers with an excellent selection of online sports
          betting options and live casino games. Users will receive only the
          best conditions they experience the level of service on this high-tech
          platform. Players benefit from an excellent user experience at this
          bookmaker because krishna365 is growing and evolving its outreach and
          the online gaming experiences.
        </p>
        <h3 className="mt-3">The status of online betting sites in India.</h3>
        <p>
          It has long been debated whether betting should be made legal in
          India. But the status of every betting site in India is not the same.
          As krishna365 is legal in India as an online betting site but this is
          note case with other sites as well. Each state has the authority to
          decide how to regulate betting and gaming on its own terms, in
          accordance with national legislative requirements. As a result, you
          should become familiar with the laws of your home state before you
          begin playing on the platform as you would not experience the same
          gaming norms from all the other regular platforms. And since
          krishna365 is legal in India, you won't be allowed to register for an
          account on krishna365 if betting is illegal in your country ot your
          state because it's goes against the krishna365’s laws and against
          business policy.
        </p>
        <h3 className="mt-3">The regulations governing online betting</h3>
        <p>
          Players are bound by their home country's rules when using the
          krishna365 sports platform. This implies that the laws in your
          location is what will determine whether online betting is legal or
          not. As per the regulations, the users may use online bookmaker
          betting sites without breaking any Indian legal restrictions.
        </p>
        <h3 className="mt-3">Is Krishna365 legal in India?</h3>
        <p>
          Yes, betting on krishna365 is legal in India for sports like cricket,
          play live casino and other sports and is permissible under Indian law.
          The applicable licencing authorities also regulate the bookmaker. The
          business is very popular and considered one of the most trusting
          platform for online sports betting and works as a international agent
          with is legally established without breaking any national-
          international regulations. The greatest advantage of playing with
          krishna365 is that it solely provides legal means to play games online
          very smoothly and earn a lot of money without any external hustle.
        </p>
        <h4>
          Consequently, the following are the primary justifications for the
          company's legality:
        </h4>
        <ul>
          <li>• Bookmaker upholds the values of krishna365</li>
          <li>• Curacao gaming licence</li>
          <li>• reputable business since establishment</li>
          <h4 className="mt-3">The following requirements must be met</h4>
          <li>
            • Reading comprehension of the conditions of use on the official
            website
          </li>
          <li>• Collaboration with providers of legal software and</li>
          <li>• Compliance with Indian legal requirements</li>
          <h6 className="mt-1 mb-1">
            Players merely need to take pleasure in a comfy game based on the
            aforementioned aspects. Opening an account, placing bets, and making
            deposits on the platform are all legal.
          </h6>
        </ul>
        <h3 className="mt-3">Krishna365, most trusted platform</h3>
        <p>
          The top casino games can be played and be betted on the official
          Krishna365 platform as it is considered a trusted platform for online
          betting games where Indian gamers may also win real money. For the
          sake of maintaining a good reputation, the business only provides
          high-quality service and trustworthy payment options. Additionally,
          the reason krishna365 is very popular in India as bookmaker does
          everything in its power to protect customer personal data, and does
          not share it with third parties. By virtue of the Curacao Gambling
          Commission's regulation of the business, krishna365 is recognised as a
          legitimate bookmaker and its services are permitted. For more details
          and to read the terms and conditions of the website krishna365 website
          is 24x7 available.
        </p>
        <h3 className="mt-3">Benefits of playing with keishna365</h3>
        <p>
          The best circumstances and prospects for financial gain are provided
          to Indian participants because krishna365 concentrates on the Asian
          market. It is a dependable betting and betting site.
        </p>
        <h4 className="mt-2">What the business provides is:</h4>
        <ul>
          <li>
            • Every instrument need for easy income and comfortable gaming
          </li>
          <li>
            • Krishna365 is legal in India; there is a large selection of sports
            disciplines with good odds; there is an official website to begin
            the game and there are pleasant bonuses and profitable promotions.
          </li>
          <li>
            • A range of online casino games, secure payment options, the
            privacy and safety of players' personal information, and 24x7
            customer service are all provided.
          </li>
        </ul>
        <h3 className="mt-3">Krishna365 Customer Support</h3>
        <p>
          {" "}
          The job doesn’t end with just legalising krishna365 in India, there’s
          always more we have to offer. We believe in providing the most
          comfortable and better experience to our users which is why we
          emphasize on improving the customer support every day for you. To
          provide better customer service, the platform has a qualified
          krishna365 support team. You can always be assured that assistance
          will be provided immediately upon your request. You can connect with
          the krishna365 customer support team via:
        </p>
        <ul>
          <li>• Live Chat – available on any page of the site 24/7;</li>
          <li>• Social Media- facebook</li>
          <li>• instagram- https://www.instagram.com/krishna365official/</li>
          <li>• Via Email address- officialkrishna365@gmail.com</li>
          <li>• Watsapp- +91 8888880808</li>
        </ul>
        <p>
          When approaching to customer support we assure to to get a quick
          response, regardless of all sorts of questions. Krishna365 is very
          flexible in answering all the queries with the advantage of both the
          languages English and Hindi.Our customer support team is available
          around the clock to assist you with any queries or concerns you may
          have. We strive to provide prompt and reliable support, ensuring that
          your experience with our site remains smooth and hassle-free.{" "}
        </p>
        <h3 className="mt-3">
          How to deposit money online with krishna365 legally?
        </h3>
        <p>
          You can deposit money into your account using secure payment options
          on the bookmaker's platform since Krishna365 is legal in India it has
          been permitted the for easy money transfer methods. You can view the
          complete list on the website for deposit details.
        </p>
      </div>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </div>
  );
};

export default ComingSoon;
