export const WordCasinoGames = {
  baccarat: {
    href: "/casino/worldcasino/baccarat",
    code: "BAC",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
  },

  // Avaitor: {
  //   href: "/casino/worldcasino/Avaitor",
  //   code: "ATR",
  //   casino: "wco",
  //   provider: "PG",
  //   homeUrl: "",
  //   cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
  // },

  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      pilnko: {
        href: "/casino/spribe/pilnko",
        code: "pilnko",
        casino: "spribe",
        provider: "pilnko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "miniroulette",
        casino: "spribe",
        provider: "miniroulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hilo",
        casino: "miniroulette",
        provider: "hilo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
};
